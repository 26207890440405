.admin__body {
    display: flex;
    width: 100%;
    /* height: 100vh; */
}

.admin__nav .active {
    background: #0bff2b;
    display: block;
    color: black;
    font-weight: bold;
}

.admin__nav {
    width: 300px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background: #292929;
    transition: left 0.3s ease-in-out;
}
.admin__nav.admin__show {
    left: -300px;
}

.admin__nav--logo {
    display: flex;
    justify-content: center;
}

.admin__nav--icon {
    min-width: 40px;
}

.admin__nav--item {
    width: 100%;
    display: flex;
    padding: 18px 24px;
    align-items: center;
    border-bottom: 1px solid #000;
    cursor: pointer;
}

.admin__nav--item h5 {
    margin: 0 0 0 18px;
}

.admin__content {
    flex: 1;
    margin-left: 300px;
    transition: all 0.3s ease-in-out;
    background: #ccc;
    min-height: 100vh;
}

.admin__content.full__width {
    margin-left: 0;
}

.admin__content--header.header__fullwidth {
    left: 0;
}

.admin--page {
    padding: 0 16px 0 0;
}

.notify__icon--admin--page {
    color: white;
}

.admin__content--header {
    z-index: 9;
    transition: all 0.3s ease-in-out;
    position: fixed;
    right: 0;
    height: 80px;
    left: 300px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #292929;
    border-left: 1px solid #fff;
    padding: 0 16px 0 0;
}

.admin__content--header--left {
    display: flex;
    align-items: flex-end;
}

.admin__content--header--text {
    color: white;
    margin: 0;
}

.admin__content--header--avatar {
    width: 40px;
    border-radius: 50%;
}

.toggle__navbar {
    padding: 12px;
    margin-left: 4px;
    cursor: pointer;
}

.role__item {
    background: #cabdbd;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.product__thumb {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.admin__wrap--content {
    position: relative;
    min-height: calc(100vh - 150px);
}

.foreignClass {
    background: #ccc;
}

.role__item {
    border-radius: 8px;
    color: #ffffffde;
}

.role__item.product {
    background: #321fdb;
}

.role__item.user {
    background: #f9b115;
}

.role__item.order {
    background: #e55353;
}

.role__item.ordered {
    background: #3399ff;
}

/*product*/
.product_detail {
    display: flex;
    justify-content: space-between;
}

.product__thumb.detail {
    width: 100px;
    height: 100px !important;
    object-fit: cover;
    height: unset;
}

.product__list--img {
    display: inline-flex;
    margin-left: 8px;
}

.product__detail--item {
    border-bottom: 1px solid #ccc;
    padding: 12px 0;
}
.product__detail--item.description,
.product__detail--item.description textarea {
    width: 100%;
}
.product__detail--item b {
    min-width: 110px;
    display: inline-block;
}
.product__detail--item input {
    border: none;
    outline: none;
}

.product__detail--item.product__detail--item--imgs {
    min-height: 100px;
    display: flex;
    align-items: center;
}

.product__list--img--item {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.select__type {
    border: none;
}
label {
    cursor: pointer;
}

label.label__change__image input[type='file'] {
    position: absolute;
    top: -1000px;
}
.label__change__image {
    text-align: center;
    cursor: pointer;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 4px 8px;
    margin: 0 0 0 32px;
    background: #dddddd;
    display: inline-block;
}
.label__change__image:hover {
    background: var(--primary-color--);
    color: white;
}
.label__change__image:active {
    background: #9fa1a0;
}
.label__change__image:invalid + span {
    color: #000000;
}
.label__change__image:valid + span {
    color: #ffffff;
}

.product__detail--item.info__disable {
    cursor: not-allowed;
}

span.ant-avatar.ant-avatar-circle.ant-avatar-image.css-dev-only-do-not-override-1w4v3hc {
    width: 54px;
    height: 54px;
}

.btn__add--type--product {
    margin: 12px 0;
}

.notify__page--head {
    display: flex;
    justify-content: space-between;
}

.recharts-surface {
    padding: 50px !important;
}
