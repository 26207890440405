.wrap_cart,
.wrap_notify,
.search {
    padding: 12px 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 999;
    color: black;
    overflow-y: scroll;
    height: 340px;
    top: 100%;
    right: 0;
    min-width: 420px;
    border: 1px solid var(--primary-color--);
    border-radius: 3px;
    position: absolute;
    display: block;
    background: white;
    animation: show 0.5s linear;
    transform-origin: top;
    @keyframes show {
        from {
            opacity: 0;
            transform: scaleY(0.4);
        }
        to {
            opacity: 1;
        }
    }
    .delete {
        position: absolute;
        right: 12px;
        padding: 4px;
        min-width: 50px;
        border: none;
        outline: none;
        background-color: var(--primary-color--);
        color: white;
        border-radius: 999px;
        margin: 10px 0;
        margin-left: 60px;
        font-weight: 600;
    }

    b {
        font-size: 16px;
    }

    .content {
        text-align: left;
        div {
            display: flex;
            align-items: center;

            .price {
                text-align: left;
                display: inline-block;
                min-width: 140px;
            }

            .b {
                min-width: 30px;
                text-align: center;
                font-size: 18px;
                margin: 0 8px;
                color: var(--primary-color--);
            }
            span {
                border-radius: 50%;
                font-size: 20px;
                font-weight: 900;
                padding: 0 4px;
                min-width: 30px;
                display: inline-flex;
                justify-content: center;
                background-color: transparent;
                color: black;
                cursor: pointer;
            }
        }
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #d62929;
    }

    .items {
        padding: 4px 0;
        border-bottom: 1px solid var(--primary-color--);
        display: flex;
        align-items: center;
        animation: none;
        color: black;
    }

    .images {
        width: 54px;
        height: 54px;
        padding: 8px;
    }

    footer {
        margin-top: 32px;
        text-align: center;
    }

    .total_price {
        display: block;
    }
    .button {
        padding: 8px 16px;
        border: none;
        outline: none;
        background-color: var(--primary-color--);
        color: white;
        border-radius: 999px;
        margin: 10px 0;
        font-weight: 600;
    }
}

.wrap_notify {
    padding: 0;

    .items {
        padding: 12px;
        justify-content: space-between;
        border-bottom: 1px solid #5d5c5c;
        cursor: pointer;

        &:hover {
            background: #f3f3f3;
        }
    }
    .content {
        max-width: 240px;
        overflow: hidden;
        .price {
            white-space: nowrap;
        }
    }
}
