:root {
    --primary-color--: #a50a06;
}
.App {
    /* min-height: 120vh; */
    /* background: #ccc; */
}

body::-webkit-scrollbar {
    display: none;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
}

.item {
    width: 5px;
    height: 30px;
    background: black;
    margin: 2px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
}

.item::nth-child(2) {
    animation-delay: 0.1s;
}
.item::nth-child(3) {
    animation-delay: 0.2s;
}
.item::nth-child(4) {
    animation-delay: 0.3s;
}
.item::nth-child(5) {
    animation-delay: 0.4s;
}
.item::nth-child(6) {
    animation-delay: 0.5s;
}
.item::nth-child(7) {
    animation-delay: 0.6s;
}
.item::nth-child(8) {
    animation-delay: 0.7s;
}
.item::nth-child(9) {
    animation-delay: 0.8s;
}
.item::nth-child(10) {
    animation-delay: 0.9s;
}

@keyframes wave {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes scale {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.overlay-login {
    position: fixed;
    max-width: 100% !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    animation: scale 0.4s linear;
}

.wrap {
    margin: 0 auto;
    height: 450px;
    background: white;
    padding: 36px 48px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 5px;
}

#scroll {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 5px;
    background-color: aqua;
    width: 200px;
}
.bill-container {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
}

.bill-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.bill-table {
    width: 100%;
    border-collapse: collapse;
}

.bill-table th,
.bill-table td {
    padding: 8px;
    border-bottom: 1px solid #ccc;
}

.bill-table th {
    background-color: #f2f2f2;
}

.bill-total {
    margin-top: 10px;
    text-align: right;
    font-weight: bold;
}

.button-active {
    background-color: rgb(13, 205, 87);
    color: white;
}

.billing-container {
}

@keyframes showBilling {
    0% {
        opacity: 0.5;
        transform: translateX(50%);
    }
    80% {
        opacity: 1;
        transform: translateX(0);
    }
    90% {
        opacity: 1;
        transform: translateX(1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
.billing-wrapper {
    z-index: 99999;
    text-align: center;
    width: 900px;
    max-width: 100%;
    position: fixed;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    background-color: #f2f2f2;
    animation: showBilling 0.7s ease-out;
}

.billing-wrapper .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px 8px;
    color: white;
    font-weight: 700;
    background-color: red;
}

.billing-table {
    width: 100%;
    border-collapse: collapse;
}

.billing-table th,
.billing-table td {
    padding: 8px;
    border: 1px solid #ccc;
}
.billing-table img {
    width: 50px;
}
.billing-total {
    margin-top: 20px;
}

.billing-customer-info,
.billing-payment-method {
    margin-top: 40px;
}

.billing-button {
    margin-top: 40px;
    padding: 10px 20px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.billing-button:hover {
    background-color: #d32f2f;
}
.billing-total h3,
.billing-customer-info h3,
.billing-payment-method h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.billing-total {
    margin-top: 20px;
    font-size: 16px;
}

.billing-customer-info,
.billing-payment-method {
    margin-top: 40px;
}

.billing-loader {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 249, 249, 1);
    align-items: center;
    justify-content: center;
}

.subLoading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.overlay-wrap {
    margin-top: 132px !important;
}

.footer {
    width: 100%;
}

.footer__head {
    background-color: #2d2d2d;
    padding: 10px 0;
}

.footer__head--content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer__head--left {
    display: flex;
}

.footer__head--left-item {
    padding: 0 8px;
}

.footer__head--right {
    color: white;
    display: flex;
    align-items: center;
}

.footer__head--right--text {
    padding: 0 10px;
}

.footer__content {
    padding: 32px 0 16px 0;
}

.footer__content--item {
    width: 25%;
    padding: 0 12px;
}

.footer__content--contact {
    display: flex;
    align-items: center;
    padding: 8px 0;
}

.footer__contact--text {
    margin-left: 8px;
}

.footer__contact--text > span {
    margin-left: 8px;
    font-weight: 400;
}

.footer__content--pay {
    padding: 16px 0;
}

.copyright {
    padding-top: 24px;
    font-weight: 400;
}

.footer__input--content {
    position: relative;
    width: 60%;
    height: 36px;
}

.footer__input--text {
    width: 100%;
    height: 100%;
    padding: 0 16px;
    border-radius: 25px;
    border: none;
    outline: none;
}

.footer__input--icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: #fabd0d;
    color: white;
}

.footer__input--icon {
    color: black;
    padding: 0 8px;
}

.Toastify__toast-container--top-center {
    margin-top: 120px;
}

@media screen and (max-width: 600px) {
    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        top: 70px !important;
        width: 70% !important;
        transform: translateX(25%) !important;
    }
    .res_form {
        margin-top: 20px !important;
    }
    .wrap {
        width: 100% !important;
    }
    .res_cake {
        margin: 0 auto;
    }
    .footer__head--content {
        flex-direction: column-reverse;
    }

    .footer__head--right {
        flex-wrap: wrap;
        justify-content: center;
    }
    .footer__head--right--text {
        display: inline-block;
        width: 60%;
    }
    .footer__input--content {
        width: 90%;
        margin: 12px 0;
    }

    .footer__content--item {
        padding: 0 12px;
    }

    .footer__head--right--icon {
        padding: 0 8px;
    }

    .footer__content--logo {
        margin: 0 auto;
        display: block;
    }
    .rowss {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }

    .footer__pay--img {
        margin: 0 auto;
        display: block;
    }
    .rowss > * {
        width: 90% !important;
        padding-right: unset;
        padding-left: unset;
        margin-top: unset;
    }
    .footer__content--item {
        padding: 0 12px !important;
    }
    .footer__content--contact {
        padding: 0 12px !important;
    }
    .footer__content--item:first-child {
        padding: 0 !important;
    }
    .footer__content--contact:last-child {
        margin-bottom: 8px;
    }
    .footer__contact--text {
        margin: 0;
    }
    .footer__contact--text > span {
        margin-left: 8px;
        font-weight: 400;
    }

    .wrap-container .row > * {
        padding: unset;
    }

    .product__content--img--foreign {
        position: absolute !important;
        top: 38px !important;
        left: 25% !important;
        width: 50% !important;
        height: 20% !important;
        object-fit: cover !important;
    }
    .wrap_cart,
    .search {
        width: 100vw;
        min-width: 100vw !important;
    }
    .search {
        left: -75px !important;
    }
    .wrap_cart button {
        margin-left: 40px !important;
    }

    .res_logo {
        display: none;
    }
}

.menu__options {
    z-index: 9;
    width: 200px !important;
}

.button__left__position {
    display: flex;
}
.flex-1 {
    flex: 1;
}

.tabs__right {
    position: relative;
    background-color: rgb(230, 230, 230);
}

.ant-descriptions.css-dev-only-do-not-override-1w4v3hc {
    padding-bottom: 64px !important;
}

.cart__info--option {
    margin: 0 12px;
    display: flex;
}
.cart__info--option.modifier {
    margin-right: 100px;
}
