.overlay-wrap {
    display: flex;
    justify-content: center;
}
#normal_login {
    display: flex;
    flex-direction: column;
}

.email {
    width: 400px;
}
