.detail_component {
    background-color: #f8f8f8;
    /* min-height: 100vh; */
}

.sub__img {
    width: 380px;
    margin: 12px auto;
    overflow-x: auto;
}
.sub__imgs {
    margin: 12px auto;
    display: flex;
}

.sub__img::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}
select {
    border-radius: 18px;
    word-wrap: normal;
    outline: none;
    background: transparent;
    padding: 4px;
    min-width: 60px;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
.sub__img::-webkit-scrollbar {
    height: 8px;
    background-color: #f5f5f5;
}

.sub__img::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d62929;
}
.sub__img--item {
    object-fit: cover;
    width: 80px !important;
    height: 80px !important;
    margin-right: 12px;
}
.sub__img--item:hover {
    border: 1px solid #ff2d2d;
    cursor: pointer;
}

.detail_img {
    object-fit: cover;
    display: flex;
    justify-content: center;
}

.ant-image.css-dev-only-do-not-override-w8mnev {
    width: 100%;
    text-align: center;
}

.detail_info--head {
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.select {
    margin-bottom: 24px;
    padding: 12px 24px;
    min-height: 100px;
    border: 1px solid #ff4040;
}

.detail__info--number--selected {
    margin-bottom: 0;
    margin-right: 24px;
}
.detail__info--number--input {
    max-width: 50px;
    text-align: center;
    height: 28px;
    border: 1px solid rgb(118, 118, 118);
    border-left: 0;
    border-right: 0;
    outline: none;
}

.ant-image-preview-wrap {
    z-index: 10000000 !important;
}

.ant-image-preview-root .ant-image-preview-img {
    max-width: 60%;
    max-height: 60%;
    min-height: 60%;
}

.detail__info--number--btn {
    height: 28px;
    border: 1px solid rgb(118, 118, 118);
    line-height: 10%;
    border-radius: 0;
}

.detail_info--head.detail__btn {
    margin-top: 24px;
}

.detail__btn--order {
    background-color: #ff4040;
    border: #ff3d3d;
    color: #fff;
    margin-right: 12px;
}

.detail__btn--order:hover {
    color: #fff !important;
    outline: none;
}

.detail_info--brand {
    margin-right: 24px;
}
.detail_info--sold {
    padding-right: 24px;
    border-right: 1px solid #ccc;
}
.detail_info--sold,
.detail_info--original--price,
.detail__info--number--selected {
    min-width: 100px;
}
.detail_info--inventory {
    padding-left: 24px;
    border-left: 1px solid #ccc;
}

.detail_info--original--price {
    text-decoration: line-through;
    padding-right: 24px;
    font-weight: 100;
    color: rgb(184, 184, 184);
}

.detail_info--discounted--price {
    color: red;
    padding: 0 24px;
    border-right: 2px solid #ccc;
    border-left: 2px solid #ccc;
}

.detail_info--original--sale {
    padding: 0 12px;
    margin-left: 12px;
    background-color: #ff4040;
    color: white;
}

.detail_info--footer {
    margin-right: 18px;
}
/* 
.ant-image.css-dev-only-do-not-override-1g853jt {
    display: block;
} */
span.ant-radio-button.ant-radio-button-checked {
    background: #ff4040 !important;
}
